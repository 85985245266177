@import "../node_modules/swiper/swiper-bundle.css";
@import "./app//shared/styles/variables";

/**********Titles**********/
.title1 {
  font-size: var(--fz-title1) !important;
}

.title2 {
  font-size: var(--fz-title2) !important;
}

.title3 {
  font-size: var(--fz-title3) !important;
}

.title4 {
  font-size: var(--fz-title4) !important;
}

.title5 {
  font-size: var(--fz-title5) !important;
}

/**********Subtitles**********/
.subtitle1 {
  font-size: var(--fz-subtitle1) !important;
}

.subtitle2 {
  font-size: var(--fz-subtitle2) !important;
}

.subtitle3 {
  font-size: var(--fz-subtitle3) !important;
}

.spacing-component {
  margin-top: 100px;
  margin-bottom: 100px;
}

.scroll-container {
  background: transparent url('./assets/images/BG-purple.svg') 0% 0% no-repeat padding-box;
}

.backgroundImg {
  background: transparent url('./assets/images/BG-purple.svg') 0% 0% no-repeat padding-box;
}

.fondoPuple {
  background: transparent url('./assets/images/BG-purple.svg') 0% 0% no-repeat padding-box;
  background-size: cover;
  padding-bottom: 250px;
}

.swiper {
  width: 100%;
  height: 160px;
  margin: 50px auto;
}

/*Carousel Cotizaciones*/
.cardBorroso {
  color: red !important;
  border-radius: 20px;
  background-color: rgb(213, 186, 240);
  filter: blur(4px);
  opacity: 0.6;
}

.swiper-slide:not(.swiper-slide-active) {
  @media only screen and (max-width: 1220px) {
    color: red !important;
    border-radius: 20px;
    background-color: rgb(213, 186, 240);
    filter: blur(3.5px);
  }
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: white;
  font-weight: bold;
}
///////////////////////
/* Importing Bootstrap SCSS file. */
@import "../node_modules/bootstrap/scss/bootstrap";

:root {
  --swiper-navigation-color: #FFF;
}