:root {
    /* Font Size */
    --fz-title1: 64px;
    --fz-title2: 48px;
    --fz-title3: 40px;
    --fz-title4: 34px;
    --fz-title5: 20px;

    --fz-subtitle1: 24px;
    --fz-subtitle2: 20px;
    --fz-subtitle3: 18px;

    /* Font Family */
    --roboto: 'Roboto',
        sans-serif;
}